import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { IconCloseSmall } from '@pijma/icon-gallery/crypto'
import { IconLongSmallArrowRight } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { Color } from '../../../../../generated/quicktype/Tokens'
import { EShop } from '../../../../stores/types/EShop'
import { H3Weight600, H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLandingLayout } from '../../../common/layout/flex-box/FlexBoxCenteredLandingLayout'
import { BodyText500 } from '../../../common/text/BodyText'

const STEAM_LINK = 'https://store.steampowered.com/'

const Header = styled.div<Color>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(color) => color.bg.page.value};
  padding: 0 4px;
`

const IslandBlock = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 22px;
  font-weight: 600;
  justify-content: space-between;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  max-width: 420px;
  white-space: pre-wrap;
`

const CloseIconWrapper = styled.div<{ shadow: string }>`
  cursor: pointer;
  height: 32px;
  width: 32px;
  position: fixed;
  top: 16px;
  right: 16px;
  align-content: center;
  text-align: center;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: ${(styleProps) => styleProps.shadow};
`

const ContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  gap: 16px;
  margin-bottom: 24px;

  a {
    color: #000000;

    :hover {
      color: #000000;
    }
  }
`

const ContentHeader = styled.div`
  padding: 0 4px;
`

const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`

const IconHamburgerWrapper = styled.div`
  margin-left: 5px;
  height: 24px;
`

const ImageWrapper = styled.div`
  margin-bottom: 44px;
`

const IconArrow: FC = () => (
  <IconLongSmallArrowRight
    height={24}
    width={24}
    color={useToken().color.icon.light_bg.secondary.default.value}
  />
)

export const HelpLoginPage = () => {
  const intl = useIntl()
  const redirectTo = useNavigate()
  const crossShadow = useToken().shadow.z2.value

  return (
    <FlexBoxCenteredLandingLayout>
      <Container>
        <Header {...useToken().color}>
          <IslandBlock>
            <H3Weight600>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.title' })}
            </H3Weight600>
            <CloseIconWrapper
              shadow={`${crossShadow.x} ${crossShadow.y} ${crossShadow.blur} ${crossShadow.spread} ${crossShadow.color}`}
            >
              <IconCloseSmall
                onClick={() => redirectTo(`../${EShop.STEAM}`)}
                height={24}
                width={24}
              />
            </CloseIconWrapper>
          </IslandBlock>
        </Header>
        <ContentBlock>
          <ContentHeader>
            <H4>
              {intl.formatMessage({
                id: 'shopSteamHelpLoginPage.bySteamOnPhone',
              })}
            </H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.login' })}
              <a href={STEAM_LINK}>
                {intl.formatMessage({ id: 'shopSteamHelpLoginPage.toSteam' })}
              </a>
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.touchIcon' })}
            </BodyText500>
            <IconHamburgerWrapper>
              <img
                width={16}
                height={24}
                alt={'hamburger'}
                src={'/img/assets/iconHamburger.svg'}
              />
            </IconHamburgerWrapper>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.loginInMenu' })}
            </BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'webmob'} src={'/img/assets/picWebmob.svg'} />
        </ImageWrapper>
        <ContentBlock>
          <ContentHeader>
            <H4>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.bySteamOnPC' })}
            </H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.login' })}
              <a href={STEAM_LINK}>
                {intl.formatMessage({ id: 'shopSteamHelpLoginPage.toSteam' })}
              </a>
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({
                id: 'shopSteamHelpLoginPage.loginInAccount',
              })}
            </BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'webdesk'} src={'/img/assets/picWebdesk.svg'} />
        </ImageWrapper>
        <ContentBlock>
          <ContentHeader>
            <H4>
              {intl.formatMessage({
                id: 'shopSteamHelpLoginPage.bySteamOnApp',
              })}
            </H4>
          </ContentHeader>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.loginInApp' })}
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({
                id: 'shopSteamHelpLoginPage.touchProfileIcon',
              })}
            </BodyText500>
          </ContentRow>
          <ContentRow>
            <IconArrow />
            <BodyText500>
              {intl.formatMessage({ id: 'shopSteamHelpLoginPage.seeLogin' })}
            </BodyText500>
          </ContentRow>
        </ContentBlock>
        <ImageWrapper>
          <img alt={'app'} src={'/img/assets/picApp.svg'} />
        </ImageWrapper>
      </Container>
    </FlexBoxCenteredLandingLayout>
  )
}
