import { FC } from 'react'
import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import { EApplicationStatus } from '@api/types'
import { useToken } from '@hooks'
import { RootState } from '@stores'
import { localeNumber, useInterval } from '@utils'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { commonApplicationClient } from '../../../../api/clients/common-application-client'
import { fromResponse } from '../../../../api/types/TApplicationStatus'
import { TSellSenderInfoState } from '../../../../stores/types/TSellSenderInfoState'
import { H3Weight600, H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import NavigationBar from '../../../common/navigation/NavigationBar'
import { TextProps } from '../../../common/text/TextProps'
import { TextSpan } from '../../../common/text/TextSpan'
import { Timer } from '../../../common/timer'

const HeaderBlock = styled.div`
  margin-top: 20px;
  margin-bottom: 12px;
  margin-left: 4px;
  font-weight: 600;
`

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
  padding: 0 20px;
`

const TraderBlock = styled.div`
  display: flex;
  width: 100%;
  margin: 8px 0;
`

const TraderBlockTitle = styled.div`
  margin: 12px 0 8px;
`

const IslandPadding = styled.div`
  padding: 16px 20px 20px;
`

const ReceiverDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 8px;
  flex: 1 0 0;
  width: auto;
  vertical-align: middle;
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  vertical-align: middle;
`
const SimpleTextBlock = styled.div`
  color: #868686;
`

const LabelText = styled(SimpleTextBlock)`
  padding: 2px 0;
`

const OuterLabelText = styled.div`
  color: #868686;
  margin-left: 4px;
`

const GreenText = styled.div`
  color: #7ca525;
`

const TextDealIcon = styled.img`
  margin: 0 4px 0 0;
  vertical-align: text-bottom;
`

const NavigationBarWrapper = styled.div`
  padding-left: 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

const TraderInfoText = styled.span`
  line-height: 24px;
`

const Caption: FC<TextProps> = (props: TextProps) => (
  <TextSpan {...useToken().text.caption['500'].value} lineHeight={'20px'}>
    {props.children}
  </TextSpan>
)

interface StateProps {
  sender: TSellSenderInfoState
}

type Props = StateProps

const P2PSellPage = (props: Props) => {
  const intl = useIntl()
  const tokens = useToken()
  const redirectTo = useNavigate()
  const pathParams = useParams()
  const applicationId = pathParams.applicationId as string
  useInterval(
    () =>
      commonApplicationClient.applicationStatus(applicationId).then((res) => {
        const applicationStatus = fromResponse(res)
        switch (applicationStatus.status) {
          case EApplicationStatus.READY_FOR_PAY:
            redirectTo('../provider-select')
            break
          case EApplicationStatus.PAYING: // do nothing
            break
          case EApplicationStatus.PAID:
            redirectTo('../success')
            break
          case EApplicationStatus.FAIL:
          case EApplicationStatus.REJECT:
            redirectTo('../../failure')
        }
      }),
    2000,
  )

  const TimerDisplayText = (timeLeft: number) => {
    const minutes = Math.round(timeLeft / (1000 * 60))
    const displayMinutes =
      minutes == 1
        ? `${minutes} ${intl.formatMessage({ id: 'p2pSellPage.minute' })}`
        : `${minutes} ${intl.formatMessage({ id: 'p2pSellPage.minutes' })}`
    return `${intl.formatMessage({
      id: 'p2pSellPage.moneyWillComeIn',
    })} ${displayMinutes}.`
  }

  return (
    <>
      <FlexBoxCenteredLayout>
        <Container {...tokens.color.font.light_bg}>
          <HeaderBlock>
            <H3Weight600>
              {intl.formatMessage({ id: 'p2pSellPage.title' })}
            </H3Weight600>
          </HeaderBlock>
          <NavigationBarWrapper>
            <NavigationBar paymentMethodDisabled dataDisabled sumDisabled />
          </NavigationBarWrapper>
          <OuterLabelText>
            <Caption>
              {intl.formatMessage({ id: 'p2pSellPage.dealNumber' })}{' '}
              {props.sender.sellId}
            </Caption>
          </OuterLabelText>
          <TraderBlock>
            <Island size="M" bgColor="primary">
              <IslandPadding>
                <GreenText>
                  <Caption>
                    <TextDealIcon
                      src={'/img/assets/iconDeal.svg'}
                      width={20}
                      height={20}
                      alt={'deal'}
                    />
                    <Caption>
                      {intl.formatMessage({
                        id: 'p2pSellPage.buyerMakesTransfer',
                      })}
                    </Caption>
                  </Caption>
                </GreenText>
                <TraderBlockTitle>
                  <H4>
                    {intl.formatMessage({ id: 'p2pSellPage.youWillReceive' })}
                  </H4>
                </TraderBlockTitle>
                <ReceiverDetails>
                  <TextContainer>
                    <LabelText>
                      <Caption>
                        {intl.formatMessage({ id: 'p2pSellPage.howMuch' })}
                      </Caption>
                    </LabelText>
                    {props.sender.amount && (
                      <TraderInfoText>
                        {localeNumber(`${props.sender.amount}`)} &#8381;
                      </TraderInfoText>
                    )}
                  </TextContainer>
                  <TextContainer>
                    <LabelText>
                      <Caption>
                        {intl.formatMessage({ id: 'p2pSellPage.fromWho' })}
                      </Caption>
                    </LabelText>
                    {props.sender.accountName && (
                      <TraderInfoText>
                        {props.sender.accountName}
                      </TraderInfoText>
                    )}
                  </TextContainer>
                  <TextContainer>
                    <LabelText>
                      <Caption>
                        {intl.formatMessage({ id: 'p2pSellPage.buyerBank' })}
                      </Caption>
                    </LabelText>
                    <TraderInfoText>{props.sender.bank}</TraderInfoText>
                  </TextContainer>
                </ReceiverDetails>
              </IslandPadding>
            </Island>
          </TraderBlock>
          <OuterLabelText>
            {props.sender.sellExpirationDate && (
              <Caption>
                <Timer
                  expirationDate={props.sender.sellExpirationDate}
                  displayText={TimerDisplayText}
                />
              </Caption>
            )}
          </OuterLabelText>
          <OuterLabelText>
            <Caption>
              {intl.formatMessage({ id: 'p2pSellPage.ifNotReceived' })}
            </Caption>
          </OuterLabelText>
        </Container>
      </FlexBoxCenteredLayout>
    </>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  sender: state.p2pSell,
})

export default connect(mapStateToProps)(P2PSellPage)
