import numeral from 'numeral'

numeral.register('locale', 'custom', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function () {
    return ''
  },
  currency: { symbol: '₽' },
})

numeral.locale('custom')

export const formatAmount = (value: string) => {
  return numeral(value.toString().replace('.', ',')).format('0,0.[0000]')
}
