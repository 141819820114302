import { FC } from 'react'
import { useIntl } from 'react-intl'

import { Link } from '@pijma/crypto'

import styled from 'styled-components'

import {
  CurrencyToSymbolMap,
  EExchangePaymentMethod,
  TExchange,
} from '../../../../api/types/TExchange'
import { formatAmount } from '../../../../helpers/formatters'
import { H4 } from '../../../common/header/Headings'
import { CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  margin: 20px;
`

const Row = styled.div`
  display: flex;
  align-self: center;
  justify-content: space-between;
`

interface Props {
  exchange: TExchange
}

export const Exchange: FC<Props> = ({ exchange }) => {
  const intl = useIntl()
  const reviewCount =
    Number.parseInt(exchange.reviews.negative) +
    Number.parseInt(exchange.reviews.positive)

  const PaymentMethodTitleMap = {
    [EExchangePaymentMethod.SBP]: intl.formatMessage({
      id: 'exchange.paymentMethod.sbp',
    }),
  }

  return (
    <Container>
      <Link url={exchange.url} target="_blank">
        <div style={{ color: 'black' }}>
          <Row style={{ marginBottom: 24 }}>
            <div style={{ color: '#217AAC' }}>{exchange.name}</div>
            <H4>
              {formatAmount(exchange.rate)} {exchange.fromCurrency}
            </H4>
          </Row>

          <Row style={{ marginBottom: 4 }}>
            <CaptionText500 color="#868686">
              {intl.formatMessage({ id: 'exchange.limit' })}
            </CaptionText500>
            <CaptionText500>
              {formatAmount(exchange.minAmount)} –{' '}
              {formatAmount(exchange.maxAmount)}{' '}
              {CurrencyToSymbolMap[exchange.fromCurrency]}
            </CaptionText500>
          </Row>

          <Row style={{ marginBottom: 4 }}>
            <CaptionText500 color="#868686">
              {intl.formatMessage({ id: 'exchange.limit' })}
            </CaptionText500>
            <CaptionText500>
              {PaymentMethodTitleMap[exchange.paymentMethod]}
            </CaptionText500>
          </Row>

          <Row>
            <CaptionText500 color="#868686">
              {intl.formatMessage({ id: 'exchange.reviews' })}
            </CaptionText500>
            <CaptionText500>{reviewCount}</CaptionText500>
          </Row>
        </div>
      </Link>
    </Container>
  )
}
