import { useIntl } from 'react-intl'

import { Spinner } from '@pijma/crypto'

import styled from 'styled-components'

import { H4 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'

const DWalletLogoWrapper = styled.div`
  margin-top: 16px;
`

const HeaderWrapper = styled.div`
  margin-top: 16px;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
`

export const DWalletTransfer = () => {
  const intl = useIntl()
  return (
    <FlexBoxCenteredLayout>
      <Container>
        <Spinner size={28} />
        <HeaderWrapper>
          <H4>
            {intl.formatMessage(
              { id: 'd-wallet.transfer.header' },
              {
                br: <br />,
              },
            )}
          </H4>
        </HeaderWrapper>
        <DWalletLogoWrapper>
          <img
            src={`${process.env.PUBLIC_URL}/img/d-wallet-logo.svg`}
            alt={`Dealer Wallet`}
          />
        </DWalletLogoWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
