import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { useToken } from '@hooks'
import { Dispatch } from '@stores'
import { EPaymentSource } from '@stores/types/*'
import styled from 'styled-components'

import { isInTelegramRuntime } from '../../../../../../tg-init'
import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { appClose } from '../../../../helpers/route-utils'
import { isWebview } from '../../../../helpers/webview-utils'
import { SESSION_SOURCE_KEY } from '../../../../stores/payform/payForm'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import NavigationBar from '../../../common/navigation/NavigationBar'
import {
  BodyText600,
  CaptionText500,
  CaptionText600,
} from '../../../common/text/BodyText'

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
  padding: 0 20px;
`

const SourceTitle = styled.div`
  display: flex;
  width: 100%;
  margin: 16px 20px 0;
`

const SourceWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  margin: 8px;
  border-radius: 16px;
  cursor: pointer;

  :hover,
  :active {
    background-color: #f2f2f2;
  }
`

const SourceNameDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: fit-content;
  padding: 11px 0;
`

const SourceName = styled.div`
  align-self: stretch;
`

const SourceDescription = styled.div<DarkBgClass>`
  align-self: stretch;
  color: ${(bgClass) => bgClass.secondary.default.value};
`

const SourceValue = styled.div`
  margin: 8px 12px 8px 8px;
  height: 48px;
  width: 48px;
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

const CaptionWrapper = styled.div<DarkBgClass>`
  color: ${(bgClass) => bgClass.secondary.default.value};
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  gap: 9px;
`
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`

const CloseContainer = styled.div`
  margin: 24px 20px 0 20px;
  color: #217aac;

  span {
    cursor: pointer;

    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

type Props = DispatchProps

interface DispatchProps {
  setPaymentSource: (paymentSource: EPaymentSource) => void
}

const SellSourceSelectPage = (props: Props) => {
  const intl = useIntl()
  const redirectTo = useNavigate()

  const goToInputPayerInfo = (source: EPaymentSource) => {
    props.setPaymentSource(source)
    sessionStorage.setItem(SESSION_SOURCE_KEY, source)
    redirectTo(`../source/${source}`)
  }

  const tokens = useToken()
  return (
    <FlexBoxCenteredLayout>
      <Container {...tokens.color.font.light_bg}>
        <NavigationBarWrapper>
          <NavigationBar dataDisabled paymentDisabled sumDisabled />
        </NavigationBarWrapper>
        <Island size="M" bgColor="primary">
          <SourceTitle>
            <BodyText600>
              {intl.formatMessage({ id: 'sellSourceSelectPage.title' })}
            </BodyText600>
          </SourceTitle>
          <SourceWrapper
            onClick={() => goToInputPayerInfo(EPaymentSource.SBP)}
            data-testid="payment-methods.sbp"
          >
            <SourceValue>
              <img
                src={'/img/assets/iconSBP.svg'}
                alt={'sbp'}
                width={48}
                height={48}
              />
            </SourceValue>
            <SourceNameDescription>
              <SourceName>
                <CaptionText500>
                  {intl.formatMessage({ id: 'sellSourceSelectPage.sbp' })}
                </CaptionText500>
              </SourceName>
              <SourceDescription {...tokens.color.font.light_bg}>
                <CaptionText500>
                  {intl.formatMessage({ id: 'sellSourceSelectPage.byPhone' })}
                </CaptionText500>
              </SourceDescription>
            </SourceNameDescription>
          </SourceWrapper>
        </Island>
        <CaptionWrapper {...tokens.color.font.light_bg}>
          <CaptionText500>Powered by</CaptionText500>
          <LogoWrapper>
            <img
              src={'/img/assets/dwalletLogo.svg'}
              alt={'logo'}
              width={20}
              height={20}
            />
            <CaptionText600>Dealer Wallet</CaptionText600>
          </LogoWrapper>
        </CaptionWrapper>
        {!isInTelegramRuntime && !isWebview(navigator.userAgent) && (
          <CloseContainer onClick={() => appClose()}>
            <BodyText600>
              {intl.formatMessage({ id: 'sellSourceSelectPage.back' })}
            </BodyText600>
          </CloseContainer>
        )}
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPaymentSource: (paymentSource: EPaymentSource) => {
      dispatch.payForm.set({
        paymentSource,
      })
    },
  }
}
export default connect(null, mapDispatchToProps)(SellSourceSelectPage)
