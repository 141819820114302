import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@pijma/crypto'

import styled from 'styled-components'

import { appClose } from '../../../../helpers/route-utils'
import { H4 } from '../../../common/header/Headings'
import { IconNegative } from '../../../common/icons/IconNegative'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText600, CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  white-space: pre-wrap;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

const TipWrapper = styled.div`
  margin-top: 8px;
  margin-left: 42px;
  margin-right: 42px;
  white-space: pre-wrap;
`

const BackContainer = styled.div`
  white-space: pre-wrap;
  margin-top: 24px;
  color: #217aac;

  span {
    cursor: pointer;
    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

const ButtonWrapper = styled.div`
  margin: 32px 0 0 0;
`

export const SellInsufficientFundsPage = () => {
  const intl = useIntl()
  const redirectTo = useNavigate()
  const pathParams = useParams()
  const applicationId = pathParams.applicationId as string

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconNegative />
        <TitleWrapper>
          <H4>
            {intl.formatMessage({ id: 'sellInsufficientFundsPage.title' })}
          </H4>
        </TitleWrapper>

        <TipWrapper>
          <CaptionText500>
            {intl.formatMessage({ id: 'sellInsufficientFundsPage.tip' })}
          </CaptionText500>
        </TipWrapper>

        <ButtonWrapper>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() =>
              redirectTo(`../../application/${applicationId}/sell/sum`)
            }
          >
            {intl.formatMessage({
              id: 'sellInsufficientFundsPage.changeAmount',
            })}
          </Button>
        </ButtonWrapper>
        <BackContainer onClick={appClose}>
          <BodyText600>
            {intl.formatMessage({ id: 'sellInsufficientFundsPage.back' })}
          </BodyText600>
        </BackContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
