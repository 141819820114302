import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { Spinner } from '@pijma/crypto'

import { EApplicationStatus } from '@api/types'
import { useInterval } from '@utils'
import styled from 'styled-components'

import { commonApplicationClient } from '../../../api/clients/common-application-client'
import { fromResponse } from '../../../api/types/TApplicationStatus'
import { H4 } from '../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../common/layout/flex-box'
import { CaptionText500 } from '../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 8px;
  white-space: pre-wrap;
`

const DescriptionWrapper = styled.div`
  white-space: pre-wrap;
`

export const ShopPendingPage = () => {
  const intl = useIntl()
  const redirectTo = useNavigate()
  const pathParams = useParams()

  const applicationId = pathParams.applicationId as string
  useInterval(
    () =>
      commonApplicationClient.applicationStatus(applicationId).then((res) => {
        const applicationStatus = fromResponse(res)
        switch (applicationStatus.status) {
          case EApplicationStatus.READY_FOR_PAY:
            throw new Error('Unexpected status')
          case EApplicationStatus.PAYING: // do nothing
            break
          case EApplicationStatus.PAID:
            redirectTo('../success')
            break
          case EApplicationStatus.FAIL:
          case EApplicationStatus.REJECT:
            redirectTo('../failure')
        }
      }),
    2000,
  )

  return (
    <FlexBoxCenteredLayout>
      <Container data-testid="waiting-page">
        <Spinner size={24} />

        <TitleWrapper>
          <H4>{intl.formatMessage({ id: 'shopPendingPage.title' })}</H4>
        </TitleWrapper>

        <DescriptionWrapper>
          <CaptionText500>
            {intl.formatMessage({ id: 'shopPendingPage.description' })}
          </CaptionText500>
        </DescriptionWrapper>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
