import { FC, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { authClient } from '@api'
import { HttpStatusCode } from 'axios'

import { EShop, stringToEShop } from '../../../../stores/types/EShop'
import { H4 } from '../../../common/header/Headings'
import { SpinnerLayout } from '../../../common/layout/spinner'

const shopRegExp = /payment_(.*)/i

const parseActionToShop = (rawAction: string | null): EShop | null => {
  if (rawAction == null) {
    return null
  }
  const actionArray = rawAction.match(shopRegExp)
  if (actionArray == null || !actionArray[1]) {
    return null
  }
  return stringToEShop(actionArray[1])
}

export const AuthPage: FC = () => {
  const intl = useIntl()
  const redirectTo = useNavigate()
  const [searchParams] = useSearchParams()

  const authUuid = searchParams.get('authUuid')
  const action = searchParams.get('action')
  const shop = parseActionToShop(action)

  const [isAuthUuidLoaded, setAuthUuidLoaded] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  if (searchParams.get('successUrl')) {
    sessionStorage.successUrl = searchParams.get('successUrl')
  }

  useEffect(() => {
    if (!authUuid) {
      setErrorMessage('authUuid required')
      return
    }
    if (!action) {
      setErrorMessage('action required')
      return
    }
    authClient
      .getToken(authUuid)
      .catch((error) => {
        if (error.response.status === HttpStatusCode.NotFound) {
          redirectTo(`../not-found`, { replace: true })
        }
        setErrorMessage('error getting auth token')
      })
      .then(() => setAuthUuidLoaded(true))
  }, [authUuid, action, redirectTo])

  useEffect(() => {
    if (!isAuthUuidLoaded) {
      return
    }
    redirectTo(shop ? `/payment/${shop}` : `/${action}`, { replace: true })
  })

  return (
    <SpinnerLayout>
      {errorMessage ? (
        <H4>{intl.formatMessage({ id: 'error.tip' })}</H4>
      ) : (
        <></>
      )}
    </SpinnerLayout>
  )
}
