import { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { IconLongSmallArrowRight } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { CaptionText500 } from '../../../common/text/BodyText'

const NavigationWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 4px;
`

const IconArrow: FC = () => (
  <IconLongSmallArrowRight
    height={16}
    width={16}
    color={useToken().color.icon.light_bg.secondary.default.value}
  />
)

export const NavigationBar: FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  const primaryColor = '#000000'
  const disabledColor = useToken().color.font.light_bg.secondary.default.value

  return (
    <NavigationWrapper>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => navigate('../provider-select')}
      >
        <CaptionText500 color={disabledColor}>
          {intl.formatMessage({ id: 'navigation.paymentMethod' })}
        </CaptionText500>
      </div>
      <IconArrow />

      <CaptionText500 color={primaryColor}>
        {intl.formatMessage({ id: 'navigation.otherMethods' })}
      </CaptionText500>
    </NavigationWrapper>
  )
}
