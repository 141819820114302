import { FC, PropsWithChildren } from 'react'
import { IntlProvider as ReactIntlProvider } from 'react-intl'

import enMessages from './en.json'
import ruMessages from './ru.json'

const messages = {
  ru: ruMessages,
  en: enMessages,
} as const

type Locale = keyof typeof messages
export const locale = (navigator.language.split(/[_-]/)[0] || 'ru') as Locale

export const IntlProvider: FC<PropsWithChildren> = (props) => {
  return (
    <ReactIntlProvider
      locale={locale}
      messages={messages[locale]}
      defaultLocale="ru"
    >
      {props.children}
    </ReactIntlProvider>
  )
}
