import { useIntl } from 'react-intl'
import { useNavigate, useParams } from 'react-router-dom'

import { Button } from '@pijma/crypto'
import { IconB2CCrossSmall } from '@pijma/icon-gallery'

import { topupApplicationClient } from '@api'
import styled from 'styled-components'

import { H3Weight600 } from '../../../common/header/Headings'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText500 } from '../../../common/text/BodyText'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 12px;
  padding: 0 20px 20px 20px;
  position: absolute;
  bottom: 0;
`

const ButtonIconContainer = styled.div`
  margin-top: 24px;
  margin-right: 24px;
  position: absolute;
  align-self: end;
  top: 0;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: auto;
`

export const CancelConfirmationPage = () => {
  const intl = useIntl()
  const redirectTo = useNavigate()
  const pathParams = useParams()
  const publicPaymentId = pathParams.paymentId as string

  const cancelPayment = () => {
    topupApplicationClient.reject(publicPaymentId).then()
    redirectTo('../cancel')
  }

  const returnToP2PBill = () => redirectTo('../p2p-bill')

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <ButtonIconContainer>
          <Button onPress={() => returnToP2PBill()} shape={'circle'}>
            <IconB2CCrossSmall width={'24'} height={'24'} />
          </Button>
        </ButtonIconContainer>
        <TitleWrapper>
          <H3Weight600>
            {intl.formatMessage({ id: 'cancelConfirmation.title' })}
          </H3Weight600>

          <BodyText500>
            {intl.formatMessage({ id: 'cancelConfirmation.description' })}
          </BodyText500>
        </TitleWrapper>

        <ButtonContainer>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => cancelPayment()}
          >
            {intl.formatMessage({ id: 'cancelConfirmation.yes' })}
          </Button>

          <Button
            width={'100%'}
            size={'m'}
            type={'secondary'}
            onPress={() => returnToP2PBill()}
          >
            {intl.formatMessage({ id: 'cancelConfirmation.no' })}
          </Button>
        </ButtonContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
