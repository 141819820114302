import { FC, ReactNode } from 'react'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { FontFamily, H1Value } from '../../../../generated/quicktype/Tokens'

const HeaderText = styled.div<H1Value>`
  font-family: ${(styleProps) => styleProps.fontFamily};
  font-weight: ${(styleProps) => styleProps.fontWeight};
  font-size: ${(styleProps) => styleProps.fontSize};
  line-height: ${(styleProps) => styleProps.lineHeight};
`

interface HeaderProps {
  children: ReactNode
}

export const H1: FC<HeaderProps> = (props) => (
  <HeaderText {...useToken().text.h1.value}>{props.children}</HeaderText>
)

export const H2: FC<HeaderProps> = (props) => (
  <HeaderText {...useToken().text.h2.value}>{props.children}</HeaderText>
)

export const SecondaryH2: FC<HeaderProps> = (props) => (
  <div
    style={{ color: useToken().color.font.light_bg.secondary.default.value }}
  >
    <H2>{props.children}</H2>
  </div>
)

export const H3: FC<HeaderProps> = (props) => (
  <HeaderText {...useToken().text.h3.value}>{props.children}</HeaderText>
)

export const H3Weight600: FC<HeaderProps> = (props) => {
  let styles = useToken().text.h3.value
  styles = {
    ...styles,
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '28px',
  }

  return <HeaderText {...styles}>{props.children}</HeaderText>
}

export const H4: FC<HeaderProps> = (props) => (
  <HeaderText
    // fixme: заменить хардкод на токены, когда они появятся
    fontFamily={FontFamily.Inter}
    fontWeight="600"
    fontSize="20px"
    lineHeight="24px"
  >
    {props.children}
  </HeaderText>
)

export const SecondaryH4: FC<HeaderProps> = (props) => (
  <span
    style={{ color: useToken().color.font.light_bg.secondary.default.value }}
  >
    <H4>{props.children}</H4>
  </span>
)
