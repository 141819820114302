export interface TExchange {
  name: string

  fromCurrency: EExchangeCurrency
  toCurrency: EExchangeCurrency

  rate: string
  minAmount: string
  maxAmount: string

  url: string
  paymentMethod: EExchangePaymentMethod
  reviews: ExchangeReviewsDto
}

export enum EExchangePaymentMethod {
  SBP = 'SBP',
}

enum EExchangeCurrency {
  RUB = 'RUB',
  USDT_TRC20 = 'USDT_TRC20',
}

export const CurrencyToSymbolMap = {
  [EExchangeCurrency.RUB]: '₽',
  [EExchangeCurrency.USDT_TRC20]: 'USDT',
}

interface ExchangeReviewsDto {
  positive: string
  negative: string
}

export interface TExchangesRes {
  updated: string
  exchanges: TExchange[]
}
