import React, { FC } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { IconLongSmallArrowRight } from '@pijma/icon-gallery/crypto'

import { useToken } from '@hooks'
import styled from 'styled-components'

import { CaptionText500 } from '../text/BodyText'

const NavigationWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 100%;
  gap: 4px;
`

const IconArrow: FC = () => (
  <IconLongSmallArrowRight
    height={16}
    width={16}
    color={useToken().color.icon.light_bg.secondary.default.value}
  />
)

const TextWrapper = styled.div<{ color: string; isCursor: boolean }>`
  color: ${(props) => props.color};
  ${(props) => (props.isCursor ? 'cursor: pointer;' : '')}
`

interface WrapperProps {
  paymentMethodDisabled?: boolean
  dataDisabled?: boolean
  sumDisabled?: boolean
  paymentDisabled?: boolean
  paymentMethodRedirectLink?: string
  dataRedirectLink?: string
  sumRedirectLink?: string
  paymentRedirectLink?: string
}

const NavigationBar: FC<WrapperProps> = (props: WrapperProps) => {
  const intl = useIntl()
  const primaryColor = '#000000'
  const disabledColor = useToken().color.font.light_bg.secondary.default.value
  const linkColor = '#5817BA'

  const redirectTo = useNavigate()

  return (
    <NavigationWrapper>
      <TextWrapper
        color={
          props.paymentMethodRedirectLink
            ? linkColor
            : props.paymentMethodDisabled
            ? disabledColor
            : primaryColor
        }
        isCursor={props.paymentMethodRedirectLink !== undefined}
        onClick={() => {
          if (props.paymentMethodRedirectLink) {
            redirectTo(props.paymentMethodRedirectLink)
          }
        }}
      >
        <CaptionText500>
          {intl.formatMessage({ id: 'navigation.paymentMethod' })}
        </CaptionText500>
      </TextWrapper>
      <IconArrow />
      <TextWrapper
        color={
          props.dataRedirectLink
            ? linkColor
            : props.dataDisabled
            ? disabledColor
            : primaryColor
        }
        isCursor={props.dataRedirectLink !== undefined}
        onClick={() => {
          if (props.dataRedirectLink) {
            redirectTo(props.dataRedirectLink)
          }
        }}
      >
        <CaptionText500>
          {intl.formatMessage({ id: 'navigation.data' })}
        </CaptionText500>
      </TextWrapper>
      <IconArrow />
      <TextWrapper
        color={
          props.sumRedirectLink
            ? linkColor
            : props.sumDisabled
            ? disabledColor
            : primaryColor
        }
        isCursor={props.sumRedirectLink !== undefined}
        onClick={() => {
          if (props.sumRedirectLink) {
            redirectTo(props.sumRedirectLink)
          }
        }}
      >
        <CaptionText500>
          {intl.formatMessage({ id: 'navigation.sum' })}
        </CaptionText500>
      </TextWrapper>
      <IconArrow />
      <TextWrapper
        color={
          props.paymentRedirectLink
            ? linkColor
            : props.paymentDisabled
            ? disabledColor
            : primaryColor
        }
        isCursor={props.paymentRedirectLink !== undefined}
        onClick={() => {
          if (props.paymentRedirectLink) {
            redirectTo(props.paymentRedirectLink)
          }
        }}
      >
        <CaptionText500>
          {intl.formatMessage({ id: 'navigation.payment' })}
        </CaptionText500>
      </TextWrapper>
    </NavigationWrapper>
  )
}

export default React.memo(NavigationBar)
