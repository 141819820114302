import { FC, useState } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Button, Icons, Link, Spinner } from '@pijma/crypto'

import { fromResponse } from '@api/types'
import { Dispatch, RootState } from '@stores'
import styled from 'styled-components'

import { commonApplicationClient } from '../../../../api/clients/common-application-client'
import { useInit } from '../../../../hooks/useInit'
import { payFormService } from '../../../../service/payform'
import { H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { BodyText600, CaptionText500 } from '../../../common/text/BodyText'
import { Exchange } from './Exchange'
import { NavigationBar } from './NavigationBar'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
  background: rgb(250, 250, 250);
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

const ExchangesLabel = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 16px;
  padding: 0 4px;
`

export const AdditionalMethodsPage: FC = () => {
  const dispatch = useDispatch<Dispatch>()
  const applicationId = useParams().applicationId as string
  const intl = useIntl()
  const userId = useSelector(
    (state: RootState) => state.payForm?.application?.userId,
  )
  const exchanges = useSelector((state: RootState) => state.payForm?.exchanges)
  const address = useSelector((state: RootState) => state.payForm?.address)
  const { loading, error } = useInit(async () => {
    if (!userId) {
      const applicationRes = await commonApplicationClient.application(
        applicationId,
      )
      payFormService.initPayForm(fromResponse(applicationRes))
    }
    await Promise.all([
      dispatch.payForm.fetchUsersUSDTAddress(),
      dispatch.payForm.fetchExchanges(),
    ])
  })

  const defaultCopyTitle = intl.formatMessage({ id: 'copy.address' })
  const [copyTitle, setCopyTitle] = useState(defaultCopyTitle)
  const handleCopyPress = async () => {
    await navigator.clipboard.writeText(address?.cryptoAddress || '')
    setCopyTitle(intl.formatMessage({ id: 'copy.success' }))
    setTimeout(() => setCopyTitle(defaultCopyTitle), 2000)
  }

  const AnotherWaysText = () => (
    <>
      <div style={{ marginBottom: 8 }}>
        <H4>{intl.formatMessage({ id: 'purchase.otherMethods' })}</H4>
      </div>

      <CaptionText500 color="#868686">
        {intl.formatMessage({ id: 'purchase.otherMethodsTip' })}
      </CaptionText500>
    </>
  )

  const AddressBlock = () => (
    <>
      <div style={{ margin: '8px 0 24px' }}>
        <CaptionText500 color="#868686">
          {address?.cryptoAddress}
        </CaptionText500>
      </div>

      <Button
        icon={Icons.IconCopySmall}
        type="primary"
        width="100%"
        onPress={handleCopyPress}
      >
        {copyTitle}
      </Button>
    </>
  )

  if (loading)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', margin: 20 }}>
        <Spinner size={28} />
      </div>
    )

  return (
    <Container>
      <NavigationBarWrapper>
        <NavigationBar />
      </NavigationBarWrapper>

      {error ? (
        <>
          <Island size="M" bgColor="primary" padding="16px" margin="0 0 24px">
            <AnotherWaysText />

            <div style={{ margin: '24px 0 16px' }}>
              <BodyText600>
                {intl.formatMessage({ id: 'purchase.exchangesExamples' })}
              </BodyText600>
            </div>

            <div style={{ margin: '0 0 8px' }}>
              <Link url="https://exnode.ru/" target="_blank">
                Exnode
              </Link>
            </div>
            <div>
              <Link url="https://www.bestchange.ru/" target="_blank">
                Bestchange
              </Link>
            </div>
          </Island>

          {address && (
            <Island size="M" bgColor="primary" padding="16px">
              <H4>{intl.formatMessage({ id: 'purchase.address' })}</H4>
              <AddressBlock />
            </Island>
          )}
        </>
      ) : (
        <>
          {address && (
            <Island size="M" bgColor="primary" padding="16px" margin="0 0 24px">
              <AnotherWaysText />

              <div style={{ marginTop: 24 }}>
                <BodyText600>
                  {intl.formatMessage({ id: 'purchase.address2' })}
                  <br /> {intl.formatMessage({ id: 'purchase.address3' })}
                </BodyText600>
              </div>

              <AddressBlock />
            </Island>
          )}

          {exchanges && (
            <>
              <ExchangesLabel>
                <CaptionText500 color="#868686">
                  {intl.formatMessage({ id: 'purchase.exchanges' })}
                </CaptionText500>
                <CaptionText500 color="#868686">
                  {intl.formatMessage({ id: 'purchase.exchangesTip' })}
                </CaptionText500>
              </ExchangesLabel>

              {exchanges.map((exchange) => (
                <Island
                  key={exchange.url}
                  size="M"
                  bgColor="primary"
                  margin="0 0 16px"
                >
                  <Exchange exchange={exchange} />
                </Island>
              ))}
            </>
          )}
        </>
      )}
    </Container>
  )
}
