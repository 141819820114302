import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { EPaymentMethod } from '@api/types'
import { useToken } from '@hooks'
import { Dispatch, RootState } from '@stores'
import { EPaymentSource } from '@stores/types/*'
import styled from 'styled-components'

import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { SESSION_SOURCE_KEY } from '../../../../stores/payform/payForm'
import { EPaymentProvider } from '../../../../stores/types/EPaymentProvider'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import NavigationBar from '../../../common/navigation/NavigationBar'
import {
  BodyText500,
  BodyText600,
  CaptionText500,
} from '../../../common/text/BodyText'

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
  padding: 0 20px;
`

const SourceTitle = styled.div`
  display: flex;
  width: 100%;
  margin: 16px 20px 0;
`

const SourceWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: flex-start;
  align-self: stretch;
  flex-direction: row;
  margin: 8px;
  border-radius: 16px;
  cursor: pointer;

  :hover,
  :active {
    background-color: #f2f2f2;
  }
`

const SourceNameDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  width: fit-content;
  padding: 11px 0;
`

const SourceName = styled.div`
  align-self: stretch;
`

const SourceDescription = styled.div<DarkBgClass>`
  align-self: stretch;
  color: ${(bgClass) => bgClass.secondary.default.value};
`

const SourceValue = styled.div`
  margin: 8px 12px 8px 8px;
  height: 48px;
  width: 48px;
`

const BackContainer = styled.div`
  margin: 24px 0 0 0;
  color: #217aac;

  span {
    cursor: pointer;
    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

const NavigationBarWrapper = styled.div`
  padding: 0 4px;
  display: flex;
  width: 100%;
  align-items: start;
`

interface StateProps {
  paymentMethods: EPaymentMethod[] | undefined
}

type Props = StateProps & DispatchProps

interface DispatchProps {
  setPaymentSource: (paymentSource: EPaymentSource) => void
}

const PurchaseSourceSelectPage = (props: Props) => {
  const intl = useIntl()
  const redirectTo = useNavigate()

  const goToInputPayerInfo = (source: EPaymentSource) => {
    props.setPaymentSource(source)
    sessionStorage.setItem(SESSION_SOURCE_KEY, source)
    redirectTo(`../source/${source}`)
  }

  const tokens = useToken()
  return (
    <FlexBoxCenteredLayout>
      <Container {...tokens.color.font.light_bg}>
        <NavigationBarWrapper>
          <NavigationBar dataDisabled paymentDisabled sumDisabled />
        </NavigationBarWrapper>
        <Island size="M" bgColor="primary">
          <SourceTitle>
            <BodyText600>
              {intl.formatMessage({ id: 'purchaseSourceSelectPage.title' })}
            </BodyText600>
          </SourceTitle>
          {props.paymentMethods?.includes(EPaymentMethod.SBP) && (
            <SourceWrapper
              onClick={() => goToInputPayerInfo(EPaymentSource.SBP)}
              data-testid="payment-methods.sbp"
            >
              <SourceValue>
                <img
                  src={'/img/assets/iconSBP.svg'}
                  alt={'sbp'}
                  width={48}
                  height={48}
                />
              </SourceValue>
              <SourceNameDescription>
                <SourceName>
                  <CaptionText500>
                    {intl.formatMessage({ id: 'purchaseSourceSelectPage.sbp' })}
                  </CaptionText500>
                </SourceName>
                <SourceDescription {...tokens.color.font.light_bg}>
                  <CaptionText500>
                    {intl.formatMessage({
                      id: 'purchaseSourceSelectPage.byPhone',
                    })}
                  </CaptionText500>
                </SourceDescription>
              </SourceNameDescription>
            </SourceWrapper>
          )}
          {props.paymentMethods?.includes(EPaymentMethod.BANK_CARD) && (
            <SourceWrapper
              onClick={() => goToInputPayerInfo(EPaymentSource.CARD)}
              data-testid="payment-methods.card"
            >
              <SourceValue>
                <img
                  src={'/img/assets/iconCard.svg'}
                  alt={'card'}
                  width={48}
                  height={48}
                />
              </SourceValue>
              <SourceNameDescription>
                <SourceName>
                  <CaptionText500>
                    {intl.formatMessage({
                      id: 'purchaseSourceSelectPage.card',
                    })}
                  </CaptionText500>
                </SourceName>
                <SourceDescription {...tokens.color.font.light_bg}>
                  <CaptionText500>
                    {intl.formatMessage({
                      id: 'purchaseSourceSelectPage.byCardNumber',
                    })}
                  </CaptionText500>
                </SourceDescription>
              </SourceNameDescription>
            </SourceWrapper>
          )}
        </Island>
        <BackContainer
          onClick={() => redirectTo('../provider-select')}
          data-testid="payment-methods.back"
        >
          <BodyText500>
            {intl.formatMessage({ id: 'purchaseSourceSelectPage.back' })}
          </BodyText500>
        </BackContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => {
  const paymentMethods = (() => {
    if (state.payForm?.paymentProvider === EPaymentProvider.RT) {
      return state.paymentProviders?.RT?.providerPaymentMethods
    }
    return state.paymentProviders?.M3?.providerPaymentMethods
  })()

  return {
    paymentMethods,
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPaymentSource: (paymentSource: EPaymentSource) => {
      dispatch.payForm.set({
        paymentSource,
      })
    },
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseSourceSelectPage)
