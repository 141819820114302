import { FC } from 'react'
import { useIntl } from 'react-intl'

import { ErrorPage } from './ErrorPage'

export const InterruptPaymentPage: FC = () => {
  const intl = useIntl()
  return (
    <ErrorPage
      title={intl.formatMessage({ id: 'interruptPayment.title' })}
      tip={intl.formatMessage(
        { id: 'interruptPayment.tip' },
        {
          br: <br />,
        },
      )}
    />
  )
}
