import { useEffect, useState } from 'react'

type UseInitResult = {
  loading: boolean
  error?: Error
}

export const useInit = (func: () => Promise<void>): UseInitResult => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState<Error | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      try {
        await func()
      } catch (err) {
        setError(err as Error)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return { loading, error }
}
