import { FC } from 'react'
import { Provider } from 'react-redux'

import { Theme, ThemeProvider } from '@pijma/crypto'

import { store } from '@stores'

import { IntlProvider } from './i18n/IntlProvider'
import { Router } from './routes/router'
import { TokenProvider } from './view/common/TokenProvider'

//todo: разобраться с StrictMode (зачем он нужен) и обернуть <Router /> в него для process.env.REACT_APP_ENV === 'development'
export const App: FC = () => (
  <ThemeProvider theme={Theme.withSyncFont}>
    <IntlProvider>
      <TokenProvider>
        <Provider store={store}>
          <Router />
        </Provider>
      </TokenProvider>
    </IntlProvider>
  </ThemeProvider>
)
