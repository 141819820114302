import { CSSProperties, FC, ReactNode } from 'react'

import { useToken } from '@hooks'

import { IslandView } from './IslandView'

export interface IIslandProps {
  bgColor:
    | 'primary'
    | 'secondary'
    | 'secondaryError'
    | 'secondaryWaiting'
    | string
  size: 'M' | 'L'
  children: ReactNode
  width?: string
  height?: string
  padding?: CSSProperties['padding']
  margin?: CSSProperties['margin']

  onPress?: () => void
}

export const Island: FC<IIslandProps> = (props) => {
  const tokens = useToken()
  let bgColor
  switch (props.bgColor) {
    case 'primary': {
      bgColor = tokens.color.bg.island.filled.primary.value
      break
    }
    case 'secondary': {
      bgColor = tokens.color.bg.island.filled.secondary.value
      break
    }
    case 'secondaryError': {
      bgColor = tokens.color.common.secondary.error.value
      break
    }
    case 'secondaryWaiting': {
      bgColor = tokens.color.common.secondary.waiting.value
      break
    }
    default: {
      bgColor = props.bgColor
    }
  }

  const borderRadius =
    props.size === 'M'
      ? tokens.border_radius.m.value
      : tokens.border_radius.l.value

  const shadow = tokens.shadow.z2.value

  return (
    <IslandView
      shadow={`${shadow.x} ${shadow.y} ${shadow.blur} ${shadow.spread} ${shadow.color}`}
      borderRadius={borderRadius}
      bgColor={bgColor}
      width={props.width || '100%'}
      height={props.height || 'auto'}
      padding={props.padding}
      margin={props.margin}
      onClick={props.onPress}
    >
      {props.children}
    </IslandView>
  )
}
