import { CSSProperties } from 'react'

import styled from 'styled-components'

export interface IIslandViewProps {
  bgColor: string
  borderRadius: string
  width: string
  height: string
  shadow: string
  padding: CSSProperties['padding']
  margin: CSSProperties['margin']
  onPress?: () => void
}
export const IslandView = styled.div<IIslandViewProps>`
  background-color: ${(styleProps) => styleProps.bgColor};
  border-radius: ${(styleProps) => styleProps.borderRadius};
  width: ${(styleProps) => styleProps.width};
  height: ${(styleProps) => styleProps.height};
  box-shadow: ${(styleProps) => styleProps.shadow};
  cursor: ${(styleProps) => (styleProps.onPress ? 'pointer' : 'default')};
  padding: ${(styleProps) => styleProps.padding};
  margin: ${(styleProps) => styleProps.margin};
`
