import { FC } from 'react'
import { useIntl } from 'react-intl'

import { ErrorPage } from './ErrorPage'

export const NotFoundPage: FC = () => {
  const intl = useIntl()

  return (
    <ErrorPage title={intl.formatMessage({ id: 'error.notFound.title' })} />
  )
}
