import { useIntl } from 'react-intl'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Icons } from '@pijma/crypto'

import { EPaymentMethod } from '@api/types'
import { useToken } from '@hooks'
import { Dispatch, RootState } from '@stores'
import { TPaymentProvidersState } from '@stores/types/*'
import { localeNumber } from '@utils'
import styled from 'styled-components'

import { isInTelegramRuntime } from '../../../../../../tg-init'
import { DarkBgClass } from '../../../../../generated/quicktype/Tokens'
import { appClose } from '../../../../helpers/route-utils'
import { isWebview } from '../../../../helpers/webview-utils'
import { SESSION_PROVIDER_KEY } from '../../../../stores/payform/payForm'
import { EPaymentProvider } from '../../../../stores/types/EPaymentProvider'
import { H4 } from '../../../common/header/Headings'
import { Island } from '../../../common/island'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'
import { BodyText600, CaptionText500 } from '../../../common/text/BodyText'

const Container = styled.div<DarkBgClass>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: ${(bgClass) => bgClass.primary.default.value};
`

const ProviderBlock = styled.div`
  display: flex;
  width: 100%;
  padding: 0 20px 0;
  margin-bottom: 16px;
  cursor: pointer;
`

const ProviderRateWrapper = styled.div`
  display: flex;
  width: auto;
  align-items: flex-start;
  align-self: stretch;
  margin-left: 16px;
  margin-right: 16px;
`

const ProviderRate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  width: fit-content;
  margin-bottom: 24px;
`

const ProviderDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 4px;
  flex: 1 0 0;
  margin: 0 16px 16px 16px;
  width: auto;
`

const ProviderRateValue = styled.div`
  align-self: stretch;
  margin-top: 16px;
  font-smooth: never;
`

const ProviderRateCurrency = styled.div<DarkBgClass>`
  align-self: stretch;
  color: ${(bgClass) => bgClass.secondary.default.value};
`

const ProviderCountryIcon = styled.div`
  text-align: right;
  margin-top: 16px;
`

const HeaderTextContainer = styled.div<DarkBgClass>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${(bgClass) => bgClass.secondary.default.value};
  margin: 0 0 16px;
  padding: 0 24px;
`

const TextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const LabelText = styled.div`
  color: #868686;
`

const CloseContainer = styled.div`
  margin: 8px 20px 0 20px;
  color: #217aac;

  span {
    cursor: pointer;

    :hover {
      color: rgb(49, 49, 49);
    }
  }
`

const AnotherOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 80px 16px 16px;
  gap: 2px;
  cursor: pointer;
  position: relative;
`

interface StateProps {
  providers: TPaymentProvidersState
}

type Props = StateProps & DispatchProps

interface DispatchProps {
  setPaymentProvider: (paymentProvider: EPaymentProvider) => void
  setRate: (rate: number | undefined) => void
  setLimits: (minLimit: string, maxLimit: string) => void
}

export const PurchaseProviderSelectPage = (props: Props) => {
  const intl = useIntl()
  const redirectTo = useNavigate()

  const goToSourceSelect = (provider: EPaymentProvider) => {
    props.setPaymentProvider(provider)
    sessionStorage.setItem(SESSION_PROVIDER_KEY, provider)
    const currentProvider = (() => {
      if (provider == EPaymentProvider.RT) {
        return props.providers?.RT
      }
      return props.providers?.M3
    })()

    props.setRate(currentProvider?.providerExchangeRate)
    props.setLimits(
      currentProvider?.providerLimit.minLimit.toString() as string,
      currentProvider?.providerLimit.maxLimit.toString() as string,
    )
    redirectTo(`../source-select/`)
  }

  const tokens = useToken()

  const mapPaymentMethodToText = (method: EPaymentMethod): string => {
    switch (method) {
      case EPaymentMethod.BANK_CARD:
        return intl.formatMessage({ id: 'purchaseProviderSelectPage.allBanks' })
      case EPaymentMethod.SBP:
        return intl.formatMessage({ id: 'purchaseProviderSelectPage.sbp' })
      default:
        throw new Error('unexpected EPaymentSource')
    }
  }

  return (
    <FlexBoxCenteredLayout>
      <Container {...tokens.color.font.light_bg}>
        <HeaderTextContainer {...tokens.color.font.light_bg}>
          <CaptionText500>
            {intl.formatMessage({ id: 'purchaseProviderSelectPage.title' })}
          </CaptionText500>
          <CaptionText500>
            {intl.formatMessage({ id: 'purchaseProviderSelectPage.rate' })}
          </CaptionText500>
        </HeaderTextContainer>
        {props.providers.RT && (
          <ProviderBlock
            onClick={() => goToSourceSelect(EPaymentProvider.RT)}
            data-testid="provider"
          >
            <Island size="M" bgColor="primary">
              <ProviderRateWrapper>
                <ProviderRate>
                  <ProviderRateValue data-testid="provider.rate-value">
                    <H4>
                      {localeNumber(
                        `${props.providers.RT.providerExchangeRate}`,
                      )}{' '}
                      RUB
                    </H4>
                  </ProviderRateValue>
                  <ProviderRateCurrency
                    {...tokens.color.font.light_bg}
                    data-testid="provider.currency"
                  >
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.ruble',
                      })}
                    </CaptionText500>
                  </ProviderRateCurrency>
                </ProviderRate>
                <ProviderCountryIcon>
                  <img
                    src={'/img/assets/iconRU.svg'}
                    width={'32'}
                    height={'32'}
                    alt={'ru'}
                  />
                </ProviderCountryIcon>
              </ProviderRateWrapper>
              <ProviderDescription>
                <TextContainer data-testid="provider.limits">
                  <LabelText>
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.limit',
                      })}
                    </CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {localeNumber(
                      props.providers.RT.providerLimit.minLimit.toString(),
                    )}
                    {' – '}
                    {localeNumber(
                      props.providers.RT.providerLimit.maxLimit.toString(),
                    )}{' '}
                    ₽
                  </CaptionText500>
                </TextContainer>
                <TextContainer data-testid="provider.payment-methods">
                  <LabelText>
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.payment',
                      })}
                    </CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {props.providers.RT.providerPaymentMethods
                      .map(mapPaymentMethodToText)
                      .join(', ')}
                  </CaptionText500>
                </TextContainer>
              </ProviderDescription>
            </Island>
          </ProviderBlock>
        )}
        {props.providers.M3 && (
          <ProviderBlock
            onClick={() => goToSourceSelect(EPaymentProvider.M3)}
            data-testid="provider"
          >
            <Island size="M" bgColor="primary">
              <ProviderRateWrapper>
                <ProviderRate>
                  <ProviderRateValue data-testid="provider.rate-value">
                    <H4>
                      {localeNumber(
                        `${props.providers.M3.providerExchangeRate}`,
                      )}{' '}
                      RUB
                    </H4>
                  </ProviderRateValue>
                  <ProviderRateCurrency
                    {...tokens.color.font.light_bg}
                    data-testid="provider.currency"
                  >
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.ruble',
                      })}
                    </CaptionText500>
                  </ProviderRateCurrency>
                </ProviderRate>
                <ProviderCountryIcon>
                  <img
                    src={'/img/assets/iconRU.svg'}
                    width={'32'}
                    height={'32'}
                    alt={'ru'}
                  />
                </ProviderCountryIcon>
              </ProviderRateWrapper>
              <ProviderDescription>
                <TextContainer data-testid="provider.limits">
                  <LabelText>
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.limit',
                      })}
                    </CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {localeNumber(
                      props.providers.M3.providerLimit.minLimit.toString(),
                    )}
                    {' – '}
                    {localeNumber(
                      props.providers.M3.providerLimit.maxLimit.toString(),
                    )}{' '}
                    ₽
                  </CaptionText500>
                </TextContainer>
                <TextContainer data-testid="provider.payment-methods">
                  <LabelText>
                    <CaptionText500>
                      {intl.formatMessage({
                        id: 'purchaseProviderSelectPage.payment',
                      })}
                    </CaptionText500>
                  </LabelText>
                  <CaptionText500>
                    {props.providers.M3.providerPaymentMethods
                      .map(mapPaymentMethodToText)
                      .join(', ')}
                  </CaptionText500>
                </TextContainer>
              </ProviderDescription>
            </Island>
          </ProviderBlock>
        )}
        <ProviderBlock>
          <Island
            size="M"
            bgColor="primary"
            onPress={() => redirectTo('../additional-methods')}
          >
            <AnotherOptionsContent>
              <BodyText600>
                {intl.formatMessage({
                  id: 'purchaseProviderSelectPage.otherMethods',
                })}
              </BodyText600>

              <CaptionText500 color="#868686">
                {intl.formatMessage({
                  id: 'purchaseProviderSelectPage.otherMethodsDescription',
                })}
              </CaptionText500>

              <Icons.IconArrowRight
                style={{ position: 'absolute', right: 11, top: 16 }}
                color="#8C8C8C"
              />
            </AnotherOptionsContent>
          </Island>
        </ProviderBlock>

        {!isInTelegramRuntime && !isWebview(navigator.userAgent) && (
          <CloseContainer onClick={() => appClose()}>
            <BodyText600>
              {intl.formatMessage({
                id: 'purchaseProviderSelectPage.backToQwik',
              })}
            </BodyText600>
          </CloseContainer>
        )}
      </Container>
    </FlexBoxCenteredLayout>
  )
}

const mapStateToProps = (state: RootState): StateProps => ({
  providers: state.paymentProviders,
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    setPaymentProvider: (paymentProvider: EPaymentProvider) =>
      dispatch.payForm.set({
        paymentProvider,
      }),
    setRate: (rate: number | undefined) =>
      dispatch.payForm.set({
        rate,
      }),
    setLimits: (minLimit: string, maxLimit: string) => {
      dispatch.payForm.set({
        minLimit,
        maxLimit,
      })
    },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseProviderSelectPage)
