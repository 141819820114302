import { useIntl } from 'react-intl'

import { Button } from '@pijma/crypto'

import styled from 'styled-components'

import { appClose } from '../../../../helpers/route-utils'
import { H4 } from '../../../common/header/Headings'
import { IconNegative } from '../../../common/icons/IconNegative'
import { FlexBoxCenteredLayout } from '../../../common/layout/flex-box'

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: -80px;
`

const TitleWrapper = styled.div`
  margin-top: 16px;
  white-space: pre-wrap;
`

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 20px 20px;
  position: absolute;
  bottom: 0;
`

export const CancelPage = () => {
  const intl = useIntl()
  const closeWindow = appClose

  return (
    <FlexBoxCenteredLayout>
      <Container>
        <IconNegative />
        <TitleWrapper>
          <H4>{intl.formatMessage({ id: 'cancelPage.title' })}</H4>
        </TitleWrapper>

        <ButtonContainer>
          <Button
            width={'100%'}
            size={'m'}
            type={'primary'}
            onPress={() => closeWindow()}
          >
            {intl.formatMessage({ id: 'close' })}
          </Button>
        </ButtonContainer>
      </Container>
    </FlexBoxCenteredLayout>
  )
}
